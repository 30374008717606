import React, { Component } from 'react';
import 'intl';

import SocialLinks from './socialLinks';
import ClubsSlider from './clubsSlider.js';
import Img from 'gatsby-image';
import Link from 'gatsby-link';
import { Button, Col, Container, Row } from 'reactstrap';

import '../assets/scss/pages/_ScoutingPage.scss';

import a1 from '../assets/img/scouting-image-1.png';
import a2 from '../assets/img/scouting-image-2.png';
import a3 from '../assets/img/scouting-image-3.png';
import a4 from '../assets/img/scouting-image-4.png';
import a5 from '../assets/img/scouting-image-5.png';
import a6 from '../assets/img/scouting-image-6.png';
import a7 from '../assets/img/scouting-image-7.png';
import b1 from '../assets/img/scouting-benefit-1.png';
import b2 from '../assets/img/scouting-benefit-2.png';
import b3 from '../assets/img/scouting-benefit-3.png';
import b4 from '../assets/img/scouting-benefit-4.png';
import b5 from '../assets/img/scouting-benefit-5.png';
import b6 from '../assets/img/scouting-benefit-6.png';
import b7 from '../assets/img/scouting-benefit-7.png';
import pAnalyseW from '../assets/img/p-analyse-w.png';
import analyseSBg from '../assets/img/analyse-s-bg.png';
import { FormattedMessage } from 'react-intl';


class ScoutingPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { headerImage, headerImageMobile, clubsItems, slidesItems, intro, product, conceptBehind, workingWithPlatform, benefits, productAnalyse } = this.props;
    return (
      <div className="scouting-wrapper">

        <section className="intro-wrapper text-center">
          <div className="intro">
            <div
              className="position-absolute social-links-wrapper"
            >
              <SocialLinks/>
            </div>
            <Img
              className='img-fluid d-none d-md-block'
              style={{ position: 'initial' }}
              fixed={headerImage}
            />
            <Img
              className='img-fluid d-block d-md-none'
              style={{ position: 'initial' }}
              fluid={headerImageMobile}
            />
          </div>

          <Container>
            <Row>
              <Col md={{ offset: 2, size: 8 }}>
                <p className="text-center mt-4 mb-4">{intro.subHeader}</p>
              </Col>
            </Row>
          </Container>
        </section>

        <section
          className="text-image-section s-1 bg-w"
        >
          <Container className="">
            <Row>
              <img
                src={a1}
                alt="d"
                className="image-r ai-1"
              />
              <div className="text-l">
                <Row>
                  <Col md={{ size: 6 }}>
                    <h2>
                      <span>{product.preHeader}</span>
                      {product.header}
                    </h2>
                  </Col>
                </Row>

                <Row className="">
                  <Col md={{ size: 5, offset: 1 }}>
                    <p>{product.contentOne}</p>
                  </Col>
                </Row>
              </div>
            </Row>

            <Row className="w-2">
              <img
                src={a2}
                alt="d"
                className="image-l ai-2"
              />
              <div className="text-r">
                <Row>
                  <Col md={{ size: 5, offset: 7 }}>
                    <p>{product.contentTwo}</p>
                  </Col>
                </Row>
              </div>
            </Row>
          </Container>
        </section>
        <div className="oblique-block"/>

        <section
          className="text-image-section s-2 bg-bg"
        >
          <Container className="">
            {/*<Row className="slider">
             <Col>
             <Slider items={slidesItems}/>
             </Col>
             </Row>*/}

            <Row>
              <img
                src={a3}
                alt="d"
                className="image-r ai-3"
              />
              <div className="text-l">
                <Row>
                  <Col md={{ size: 6 }}>
                    <h2>
                      <span>packing</span>
                      {conceptBehind.header}
                    </h2>
                  </Col>
                </Row>

                <Row className="">
                  <Col md={{ size: 5, offset: 1 }}>
                    <p>{conceptBehind.contentOne}</p>
                  </Col>
                </Row>
              </div>
            </Row>

            <Row className="w-4">
              <img
                src={a4}
                alt="d"
                className="image-l ai-4"
              />
              <div className="text-r">
                <Row>
                  <Col md={{ size: 5, offset: 7 }}>
                    <p>{conceptBehind.contentTwo}</p>

                    <Button>
                      <span className="text-g">
                        <FormattedMessage id="learnMore"/>
                      </span>
                    </Button>
                  </Col>
                </Row>
              </div>
            </Row>
          </Container>
        </section>
        <div className="oblique-block"/>

        <section
          className="text-image-section s-3 bg-w"
        >
          <Container className="">
            <Row style={{ marginBottom: 100 }}>
              <Col>
                <ClubsSlider items={clubsItems}/>
              </Col>
            </Row>

            <Row>
              <img
                src={a5}
                alt="d"
                className="image-r ai-5"
              />
              <div className="text-l">
                <Row>
                  <Col md={{ size: 6 }}>
                    <h2>
                      <span>IMPECT</span>
                      {workingWithPlatform.header}
                    </h2>
                  </Col>
                </Row>

                <Row className="">
                  <Col md={{ size: 5, offset: 1 }}>
                    <p>{workingWithPlatform.contentOne}</p>
                  </Col>
                </Row>
              </div>
            </Row>

            <Row className="w-6">
              <img
                src={a6}
                alt="d"
                className="image-l ai-6"
              />
              <div className="text-r">
                <Row>
                  <Col md={{ size: 5, offset: 7 }}>
                    <p>{workingWithPlatform.contentTwo}</p>
                  </Col>
                </Row>
              </div>
            </Row>

            <Row className="">
              <img
                src={a7}
                alt="d"
                className="image-r ai-7"
              />
              <div className="text-l">
                <Row>
                  <Col md={{ size: 6 }}>
                    <p>{workingWithPlatform.contentThree}</p>
                  </Col>
                </Row>
              </div>
            </Row>
          </Container>
        </section>
        <div className="oblique-block"/>

        <section
          className="text-image-section s-4 bg-g"
        >
          {/*<ProfessionalsSay
           items={this.props.professionalsSay}
           />*/}
        </section>
        <div className="oblique-block"/>

        <section
          className="text-image-section s-5 bg-w"
        >
          <Container className="">
            <Row className="mb-4">
              <Col className="mb-4" md={{ size: 6, offset: 3 }}>
                <h2 className="text-center">
                  <span>{benefits.preHeader}</span>
                  {benefits.header}
                </h2>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <img className="float-right mr-2" src={b1}/>
                <h5>{benefits.headerOne}</h5>
                <p>{benefits.contentOne}</p>
              </Col>

              <Col md={6}>
                <img className="float-right mr-2" src={b2}/>
                <h5>{benefits.headerSeven}</h5>
                <p>{benefits.contentSeven}</p>
              </Col>

              <Col className="mt-4" md={6}>
                <img className="float-right mr-2" src={b3}/>
                <h5>{benefits.headerTwo}</h5>
                <p>{benefits.contentTwo}</p>
              </Col>

              <Col className="mt-4" md={6}>
                <img className="float-right mr-2" src={b4} style={{ width: '330px' }}/>
                <h5>{benefits.headerSix}</h5>
                <p>{benefits.contentSix}</p>
              </Col>

              <Col className="mt-4" md={6}>
                <img className="float-right mr-2" src={b5}/>
                <h5>{benefits.headerThree}</h5>
                <p>{benefits.contentThree}</p>
              </Col>

              <Col className="mt-4" md={6}>
                <img className="float-right mr-2" src={b6}/>
                <h5>{benefits.headerFive}</h5>
                <p>{benefits.contentFive}</p>
              </Col>

              <Col className="mt-4" md={{ size: 7, offset: 3 }}>
                <img className="float-right mr-2" src={b7}/>
                <h5>{benefits.headerFour}</h5>
                <p>{benefits.contentFour}</p>
              </Col>
            </Row>
          </Container>
        </section>
        <div className="oblique-block"/>

        <section
          className="text-image-section s-4 bg-g"
        >
          {/*<ProfessionalsSay
           items={this.props.professionalsSay}
           />*/}
        </section>

        <section
          className="text-image-section oblique s-6 bg-s-g"
        >
          <Container className="oblique-content">
            <Row>
              <Col md={{ size: 5 }}>
                <img className="mb-4" src={pAnalyseW}/>
                <p className="mb-4">
                  {productAnalyse.content || null}
                </p>
                <Button tag={Link} to={`${this.props.activeLangKey}/products/analysis`}>
                  <span className="text-g">
                    <FormattedMessage id="learnMore"/>
                  </span>
                </Button>
              </Col>
            </Row>
          </Container>
          <img
            src={analyseSBg}
            alt="d"
            className="bg"
          />
        </section>

      </div>
    );
  }
}

export default ScoutingPage;
