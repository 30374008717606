import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import Slider from 'react-slick';
import Flag from 'react-world-flags';

import '../assets/scss/components/_clubsSlider.scss';



class clubsSlider extends Component {
  propTypes = {
    items: PropTypes.array
  };
  defaultProps = {
    items: []
  };
  export;
  default;
  clubsSlider;

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      width: 0,
      height: 0,
      sliderSettingsClubs: {
        autoplay: true,
        infinite: true,
        centerMode: true,
        speed: 700,
        autoplaySpeed: 1000,
        slidesToShow: 2,
        slidesToScroll: 2,
        accessibility: false
      }
    };

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    if (window && window.innerWidth && window.innerHeight) {
      const sliderSettingsClubs = {
        autoplay: true,
        infinite: true,
        centerMode: true,
        speed: 700,
        autoplaySpeed: 1000,
        slidesToShow: 2,
        slidesToScroll: 2,
        accessibility: false
      };

      this.setState({ width: window.innerWidth, height: window.innerHeight });
      if (window.innerWidth >= 991) {
        sliderSettingsClubs.slidesToShow = 4;
        sliderSettingsClubs.slidesToScroll = 4;
        this.setState({ sliderSettingsClubs });
      } else if (window.innerWidth <= 500) {
        sliderSettingsClubs.slidesToShow = 1;
        sliderSettingsClubs.slidesToScroll = 1;
        this.setState({ sliderSettingsClubs });
      } else {
        sliderSettingsClubs.slidesToShow = 2;
        sliderSettingsClubs.slidesToScroll = 2;
        this.setState({ sliderSettingsClubs });
      }
    }
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === this.props.items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.props.items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;

    const flags = (icon) => {
      switch ( icon ) {
        case 'world':
          return ( <i className="fas fa-globe" /> );
        case 'europe':
          return ( <i className="fas fa-globe-europe" /> );
        default:
          return (
            <Flag
              className="flag"
              code={icon}
              height="90"
            />
          );
      }
    };

    const slides = this.props.items.map((item, index) => {
      return (
        <div>
          <div className='text-center'>
            {flags(item.icon)}
            <span>{item.name}</span>
          </div>
        </div>
      );
    });

    return (
      <section className="clubs-slider-wrapper">
        <Container className="text-center">
          <Row>
            <Col>
              <Slider
                {...this.state.sliderSettingsClubs}
                className="position-relative"
              >
                {slides}
              </Slider>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

clubsSlider.propTypes = {
  items: PropTypes.array
};

clubsSlider.defaultProps = {
  items: []
};

export default clubsSlider;

